export const globalMixin = {
  data: () => {
    return {
      // global
      showHeaderMenu: false,
      scrollableElem: null,
      showFooterMenuVisible: false
    }
  },
  created: function () {
    this.scrollableElem = document.getElementsByTagName('body')[0]
    this.showFooterMenuVisible = false
  },
  mounted() {
    this.scrollableElem.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.scrollableElem.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    helloMixin: function () {
      console.log('hello from mixin!')
    },
    handleScroll() {
      if (!this.showFooterMenuVisible) {
        const scrollPosition = this.scrollableElem.scrollTop;
        const viewportHeight = window.innerHeight;
        console.log(scrollPosition, viewportHeight);
        console.log(this.showFooterMenuVisible, scrollPosition > viewportHeight)

        this.showFooterMenuVisible = scrollPosition > viewportHeight;
      }
    }
  }
}
